* {
  box-sizing: border-box;
}


html,
body {
background :black;
}



h3 {
  margin-bottom: 10px;
}



ul {
  list-style-type: none;
}


p {
  margin: 20px 0;
}

nav {
  width: 33%;
}

nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul li {
  color: var(--text-color);
  cursor: pointer;
  padding: 5px;
}

.text {
  .title {
    font-size: 10rem;
    text-align: center;
    width: 75%;
    margin: 0 auto;
    color: #fff;
  }
  @media only screen and (max-width: 600px) {
    .title {
      font-size: 5rem;
      text-align: center;
      width: 75%;
      margin: 0 auto;
      color: #fff;
    }
  
  }

  .container {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
}


.hidden {
  display: none;
}
.about{
  margin-top: 5vh;
  z-index: 99 !important;
  padding: 4vw;
  padding-top: 0;
  font-size: 1.7rem;
  h2{
    font-size: 3rem;
  }
}

.abtMe{

  a {
    pointer-events: all;
    color: #ddd;
    text-decoration: none;
  }
  
  svg {
    fill: #ddd;
  }
  
  h1 {
    background: linear-gradient(30deg, #c850c0, #ffcc70);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 15em;
  }  

  @media only screen and (max-width: 600px) {
    h1{
      background: linear-gradient(30deg, #c850c0, #ffcc70);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      font-size: 5em;
    }
    
    nav{
      text-align: center;
    }
    }
    
}
.anim {
  width: 99vw;
  height: 99vh;
  background-color: black;
}
#root {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100.3vw;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;

}

  

:root {
  --dark-bg: rgba(15, 15, 15, 0.95);
  --spacing: 350px;
}

.experience{


  main {
    width: 100vw;
    color: white;
    z-index: 99;
    position: absolute;
    width: 100%;
    margin: 0px auto;
    padding: 120px 0px;
    
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }


  header {
    background: var(--dark-bg);
    grid-column: 5 / span 5;
    font-size: 2rem;
    padding: 2.5rem;
    margin-top: 30vh;
    margin-bottom: 30vh;
  }
  
  section {
    grid-column: 2 / 8;
    padding: 1rem;
    background: var(--dark-bg);
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: var(--spacing);
  }
  
  blockquote {
    margin: 0;
    grid-column: 2 / span 9;
    margin-bottom: var(--spacing);
  
    p {
      color: black;
      background-color: white;
      font-size: 4rem;
      display: inline;
      line-height: 1.33;
    }
  
  }
  
  li {
    list-style-type: circle;
 }
 
  .left {
    grid-column: 6 / 12;
    }
  .center{
    grid-column: 5 / span 5;
  }

  @media only screen and (max-width: 600px) {
    header{
    grid-column: 2   / span 10 !important; 
    font-size: 1em;

    }
  }
}


@media only screen and (max-width: 600px) {
  .winbox{
    width: 75vw !important;
    height:50vh !important;
    top: 15vh !important;
    right:0vh !important;
    bottom:0vh !important;
    left: 10vw !important;
    z-index: 99 !important;
  }

}




.winbox.modern{
  animation: none !important;
}

.mainNav 

header {
  position: absolute;
  overflow: hidden;
  z-index: 99;
  width: 75%;
  padding: 2vw;
  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      font-weight: 900;
      letter-spacing: 2px;
      color: #fff;
    }
    nav {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        li {
          list-style: none;
          margin: 0 60px;
          &.btn {
            padding-right: 25%;
            a {
              color: #fff;
              font-weight: 600;
              background: #23232a;
              padding: 16px 24px;
              border-radius: 10px;
            }
          }
          a {
            text-transform: capitalize;
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }
}

.text .title {
  font-size: 6rem;
  text-align: center;
  width: 75%;
  margin: 0 auto;
  color: #fff;
  span {
    display: block;
  }
  @media (max-width: 1440px) {
    font-size: 3rem;
  }
}
.loader{

  .loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #171717;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .loading-bar-container {
    width: 100px;
    height: 32px;
    background: #272727;
  }
  
  .loading-bar {
    height: 32px;
    background: #00AAFF;
  }
  

}

#scroller{


  *,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

  main {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: #f0f0f0;
    transition: background 0.25s ease-in-out;
  }
  
  main.dark {
    background: #0c0c0c;
  }
  
  a {
    cursor: pointer;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  
}


* {
  box-sizing: border-box;
}

html,
body,
#root,
.scroll {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  overflow: auto;
}

@import url("https://rsms.me/inter/inter.css");


.scroll {
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  scroll-snap-type: y proximity;
}

.scroll > div {
  scroll-snap-align: start;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  background-color: #101010;
  background: radial-gradient(circle at bottom center, #212121 0%, #101010 80%);
  font-family: "Inter var", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.caption {
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  margin: 80px;
  color: white;
  font-size: 8em;
  font-weight: 100;
  line-height: 1em;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: auto;
}

.dot {
  position: sticky;
  top: 0px;
  display: inline-block;
  max-width: 600px;
  padding: 0;
  padding: 80px;
  color: #a0a0a0;
  line-height: 1.6em;
  font-size: 15px;
  letter-spacing: 1.5px;
}

.dot > h1 {
  -webkit-font-smoothing: auto;
  pointer-events: none;
  color: white;
  font-size: 5em;
  font-weight: 100;
  line-height: 1em;
  margin: 0;
  margin-bottom: 0.25em;
}

.dot ul {
  margin-top: 2vh;
  padding-left: 80px;
  padding-top: 2vh;
}


@media only screen and (max-width: 1000px) {
  .caption {
    font-size: 4em;
  }
}

@media only screen and (max-width: 800px) {
  .caption {
    font-size: 3em;
  }
  .dot > h1 {
    font-size: 3em;
  }
}

@media only screen and (max-width: 700px) {
  .caption {
    font-size: 2em;
  }
  .dot > h1 {
    font-size: 3em;
  }
}

@media only screen and (max-width: 600px) {
  .caption {
    font-size: 1em;
  }
  .dot > h1 {
    font-size: 3em;
  }
  .dot .onlytwo{
    padding-left: 10vw !important;
  }
}


$red: #e20001;
$black: #191919;
.hamburger{
  .container {
    width: 1280px;
    min-width: 1280px;
    margin: 0 auto;
    .wrapper {
      padding: 0 48px;
      .home {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        p {
          font-size: 0.75rem;
          letter-spacing: 0.3rem;
          text-transform: uppercase;
          font-weight: 500;
        }
        h5 {
          margin-top: 260px;
          font-size: 24px;
          font-weight: 600;
          padding-right: 360px;
        }
      }
    }
  }
}
.hamburger{
  header {
    height: 100px;
    position: fixed;
    overflow: hidden;
    z-index: 99;
    margin-left: 0;
    .inner-header {
      position: fixed;
      z-index: 10;
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      left: 30px;
      color: white;
      .logo a {
        font-weight: 700;
        font-size: 1.5rem;
        text-decoration: none;
        color: inherit;
      }
      .menu {
        position: fixed;
        right: 30px;
        button {
          border: none;
          background: none;
          outline: none;
          cursor: pointer;
          font-size: 1.5rem;
          mix-blend-mode: difference;
          width: 70%;
        }
      }
    }
    .hamburger-menu {
      display: none;
      z-index: 9;
      top: 0;
      left: 0;
      right: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      .menu-secondary-background-color {
        background: $black;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: -1;
      }
      .menu-layer {
        position: relative;
        background: $red;
        height: 100%;
        overflow: hidden;
        .menu-city-background {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          height: 100%;
          width: 100%;
          opacity: 0;
          background-size: cover;
          background-repeat: no-repeat;
          animation-name: backgroundEffect;
          animation-duration: 30s;
          animation-iteration-count: infinite;
        }
        .wrapper {
          position: relative;
          .menu-links {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            top: 5vh;
            @media only screen and (max-width: 600px) {
              top: 100px;
            }
            nav {
              display: block;
              ul {
                padding: 0;
                margin-bottom: 0;
                margin-top: 10vh;
                flex-direction: column;
                margin-left: 20vw;
                @media only screen and (max-width: 600px) {
                  margin-top: 0;
                  left:3vw;
                  font-size: 3rem;
                }
                li {
                  list-style: none;
                  font-size: 4rem;
                  font-weight: 700;
                  cursor: pointer;
                  height: 20vh;
                  position: relative;
                  width: 40vw;
                  overflow: hidden;
                  @media only screen and (max-width: 600px) {
                    font-size: 3rem;
                    margin: 0;
                    padding:0;
                    width: 100vw;

                  }

                  a {
                    position: absolute;
                    color: #fff;
                    text-decoration: none;
                    &:hover {
                      color: $black;
                    }
                  }
                }
              }
            }
            .info {
              color: #fff;
              width: 300px;
              h3 {
                font-size: 2.5rem;
                margin: 8px auto;
              }
              p {
                margin: 0 auto;
                font-size: 1.5rem;
              }
            }
          }
          .locations {
            position: absolute;
            bottom: -80px;
            color: #fff;
            margin-top: 16px;
            font-size: 0.8rem;
            font-weight: 600;
            span {
              cursor: pointer;
              &:first-child {
                margin-left: 64px;
              }
              font-weight: 400;
              margin: 0 32px;
              transition: 0.3s ease-in-out;
              &:hover {
                color: #fff;
                background: $black;
                padding: 8px 24px;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
  }  
}
  
@keyframes backgroundEffect {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 40% 10%;
  }
  50% {
    background-position: 0 10%;
  }
  75% {
    background-position: 10% 40%;
  }
  100% {
    background-position: 0% 0%;
  }
}

